
import {
    computed, defineComponent, onMounted, reactive,
} from 'vue';
import { useRouter } from 'vue-router';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import Location from '@/domain/Location';
import LocationService from '@/services/LocationService';
import ProductionPartNoticeService from '@/services/ProductionPartNoticeService';
import ProductionPartNotice from '@/domain/ProductionPartNotice';
import ProductionPart from '@/domain/ProductionPart';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import useStringFormatter from '@/composable/useStringFormatter';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import Item from '@/domain/Item';
import ItemService from '@/services/ItemService';
import ProductionPartService from '@/services/ProductionPartService';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import resetProp from '@/functions/object';

type State = {
    originalNotice: ProductionPartNotice | undefined;
    notice: ProductionPartNotice | undefined;
    locations: Array<Location>;
    items: Array<Item>;
    productionParts: Array<ProductionPart>;
    loading: boolean;
    saving: boolean;
};

export default defineComponent({
    name: 'master-data-import-errors-asn-update',
    components: {
        BRow,
        BCol,
        DropdownAutocompleteSingleSelect,
        SmartTrakFooter,
        BSpinner,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const locationService = new LocationService();
        const productionPartNoticeService = new ProductionPartNoticeService();
        const itemService = new ItemService();
        const productionPartService = new ProductionPartService();

        const { titleCase } = useStringFormatter();
        const router = useRouter();

        const state = reactive<State>({
            originalNotice: undefined,
            notice: undefined,
            locations: [],
            items: [],
            productionParts: [],
            loading: false,
            saving: false,
        });

        const pageTitle = computed(() => getTitleCaseTranslation('core.button.updateData') + (state.originalNotice?.partNumber ? ` (${state.originalNotice.partNumber})` : ''));

        function locationExists(locationNumber: string | undefined) {
            return !!(locationNumber && state.locations.find((x) => x.locationNumber?.toLowerCase() === locationNumber?.toLowerCase()));
        }

        function itemExists(itemNumber: string | undefined) {
            return !!(itemNumber && state.items.find((x) => x.customerItemNumber?.toLowerCase() === itemNumber?.toLowerCase()));
        }

        function partExists(partNumber: string | undefined) {
            return !!(partNumber && state.productionParts.find((x) => x.number?.toLowerCase() === partNumber?.toLowerCase()));
        }

        onMounted(async () => {
            state.loading = true;

            const [locationsResponse, itemsResponse, productionPartsResponse, noticeReponse] = await Promise.all([
                locationService.getAllLocations(),
                itemService.getAllItems(),
                productionPartService.getAllProductionParts(),
                productionPartNoticeService.getProductionPartNotice(parseInt(props.id, 10)),
            ]);

            if (locationsResponse.success) {
                state.locations = locationsResponse.locations.filter((x) => x.locationNumber);
            }
            if (itemsResponse.success) {
                state.items = itemsResponse.items.filter((x) => x.customerItemNumber);
            }
            if (productionPartsResponse.parts) {
                state.productionParts = productionPartsResponse.parts.filter((x) => x.number);
            }

            if (noticeReponse) {
                // store original values for displaying in labels
                state.originalNotice = new ProductionPartNotice(noticeReponse);

                state.notice = noticeReponse;

                // clear out any values that are invalid
                if (!locationExists(state.notice.fromLocation)) {
                    state.notice.fromLocation = '';
                }
                if (!locationExists(state.notice.toLocation)) {
                    state.notice.toLocation = '';
                }
                if (!partExists(state.notice.partNumber)) {
                    state.notice.partNumber = '';
                }
                if (!itemExists(state.notice.containerItemNumber)) {
                    state.notice.containerItemNumber = '';
                }
                if (state.notice.includeTopCap && !itemExists(state.notice.topCapItemNumber)) {
                    state.notice.topCapItemNumber = '';
                }
                if (state.notice.palletized && !itemExists(state.notice.palletContainerItemNumber)) {
                    state.notice.palletContainerItemNumber = '';
                }
            }

            state.loading = false;
        });

        function selectFromLocation(location: Location) {
            if (state.notice) {
                state.notice.fromLocation = location?.locationNumber ?? '';
            }
        }

        function selectToLocation(location: Location) {
            if (state.notice) {
                state.notice.toLocation = location?.locationNumber ?? '';
            }
        }

        function selectPartNumber(part: ProductionPart) {
            if (state.notice) {
                state.notice.partNumber = part?.number ?? '';
            }
        }

        function selectContainerItemNumber(item: Item) {
            if (state.notice) {
                state.notice.containerItemNumber = item?.customerItemNumber ?? '';
            }
        }

        function selectTopCapItemNumber(item: Item) {
            if (state.notice) {
                state.notice.topCapItemNumber = item?.customerItemNumber ?? '';
            }
        }

        function selectPalletContainerItemNumber(item: Item) {
            if (state.notice) {
                state.notice.palletContainerItemNumber = item?.customerItemNumber ?? '';
            }
        }

        function goToProductionPartNoticeList() {
            router.push({ name: MasterDataRouteTypes.IMPORT_ERRORS.PFEP });
        }

        async function save() {
            if (!state.notice) {
                return;
            }

            // reset any (editable) empty fields to the original value
            ['fromLocation', 'toLocation', 'partNumber', 'containerItemNumber', 'topCapItemNumber', 'palletContainerItemNumber'].forEach((x) => resetProp(x, state.notice, state.originalNotice));

            state.saving = true;
            const response = await productionPartNoticeService.updateProductionPartNotice(state.notice);
            if (response?.success) {
                goToProductionPartNoticeList();
            }
            state.saving = false;
        }

        return {
            state,
            pageTitle,
            selectToLocation,
            selectFromLocation,
            selectPartNumber,
            selectContainerItemNumber,
            selectTopCapItemNumber,
            selectPalletContainerItemNumber,
            titleCase,
            getTitleCaseTranslation,
            getTranslation,
            goToProductionPartNoticeList,
            save,
            locationExists,
            itemExists,
            partExists,
        };
    },
});
