import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_dropdown_autocomplete_single_select = _resolveComponent("dropdown-autocomplete-single-select")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_orbis_card = _resolveComponent("orbis-card")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_smart_trak_footer = _resolveComponent("smart-trak-footer")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, {
    title: _ctx.pageTitle,
    "go-back": _ctx.goToProductionPartNoticeList,
    full: ""
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_smart_trak_footer, { class: "item-footer" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_button, {
            variant: "primary",
            disabled: _ctx.state.saving || _ctx.state.loading,
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.updateData')) + " ", 1),
              (_ctx.state.saving)
                ? (_openBlock(), _createBlock(_component_b_spinner, {
                    key: 0,
                    small: ""
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_ctx.state.loading)
        ? (_openBlock(), _createBlock(_component_b_spinner, { key: 0 }))
        : _createCommentVNode("", true),
      (!_ctx.state.loading)
        ? (_openBlock(), _createBlock(_component_orbis_card, {
            key: 1,
            title: _ctx.getTitleCaseTranslation('core.domain.details')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { cols: "4" }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", null, [
                        _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.fromLocation')) + " ", 1),
                        _createElementVNode("span", {
                          class: _normalizeClass(_ctx.locationExists(_ctx.state.originalNotice?.fromLocation) ? 'text-success' : 'text-danger')
                        }, " (" + _toDisplayString(_ctx.state.originalNotice?.fromLocation) + ") ", 3)
                      ]),
                      _createVNode(_component_dropdown_autocomplete_single_select, {
                        "model-value": _ctx.state.notice?.fromLocation,
                        loading: _ctx.state.loading,
                        data: _ctx.state.locations,
                        "search-by": "locationNumber",
                        "empty-text": _ctx.getTranslation('core.common.noLocations'),
                        "display-name": "name",
                        disabled: _ctx.locationExists(_ctx.state.originalNotice?.fromLocation),
                        onOnSelect: _ctx.selectFromLocation
                      }, null, 8, ["model-value", "loading", "data", "empty-text", "disabled", "onOnSelect"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_b_col, { cols: "4" }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", null, [
                        _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.toLocation')) + " ", 1),
                        _createElementVNode("span", {
                          class: _normalizeClass(_ctx.locationExists(_ctx.state.originalNotice?.toLocation) ? 'text-success' : 'text-danger')
                        }, " (" + _toDisplayString(_ctx.state.originalNotice?.toLocation) + ") ", 3)
                      ]),
                      _createVNode(_component_dropdown_autocomplete_single_select, {
                        "model-value": _ctx.state.notice?.toLocation,
                        loading: _ctx.state.loading,
                        data: _ctx.state.locations,
                        "search-by": "locationNumber",
                        "empty-text": _ctx.getTranslation('core.common.noLocationsAssociated'),
                        "display-name": "name",
                        disabled: _ctx.locationExists(_ctx.state.originalNotice?.toLocation),
                        onOnSelect: _ctx.selectToLocation
                      }, null, 8, ["model-value", "loading", "data", "empty-text", "disabled", "onOnSelect"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_b_col, { cols: "4" }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", null, [
                        _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.partNumber')) + " ", 1),
                        _createElementVNode("span", {
                          class: _normalizeClass(_ctx.partExists(_ctx.state.originalNotice?.partNumber) ? 'text-success' : 'text-danger')
                        }, " (" + _toDisplayString(_ctx.state.originalNotice?.partNumber) + ") ", 3)
                      ]),
                      _createVNode(_component_dropdown_autocomplete_single_select, {
                        "model-value": _ctx.state.notice?.partNumber,
                        loading: _ctx.state.loading,
                        data: _ctx.state.productionParts,
                        "search-by": "number",
                        "empty-text": _ctx.getTranslation('core.common.noParts'),
                        "display-name": "number",
                        disabled: _ctx.partExists(_ctx.state.originalNotice?.partNumber),
                        onOnSelect: _ctx.selectPartNumber
                      }, null, 8, ["model-value", "loading", "data", "empty-text", "disabled", "onOnSelect"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_b_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { cols: "4" }, {
                    default: _withCtx(() => [
                      _createElementVNode("label", null, [
                        _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.containerNumber')) + " ", 1),
                        _createElementVNode("span", {
                          class: _normalizeClass(_ctx.itemExists(_ctx.state.originalNotice?.containerItemNumber) ? 'text-success' : 'text-danger')
                        }, " (" + _toDisplayString(_ctx.state.originalNotice?.containerItemNumber) + ") ", 3)
                      ]),
                      _createVNode(_component_dropdown_autocomplete_single_select, {
                        "model-value": _ctx.state.notice?.containerItemNumber,
                        loading: _ctx.state.loading,
                        data: _ctx.state.items,
                        "search-by": "customerItemNumber",
                        "empty-text": _ctx.getTranslation('core.common.noItemsFound'),
                        "display-name": "name",
                        disabled: _ctx.itemExists(_ctx.state.originalNotice?.containerItemNumber),
                        onOnSelect: _ctx.selectContainerItemNumber
                      }, null, 8, ["model-value", "loading", "data", "empty-text", "disabled", "onOnSelect"])
                    ]),
                    _: 1
                  }),
                  (_ctx.state.notice?.includeTopCap)
                    ? (_openBlock(), _createBlock(_component_b_col, {
                        key: 0,
                        cols: "4"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("label", null, [
                            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.topCapItemNumber')) + " ", 1),
                            _createElementVNode("span", {
                              class: _normalizeClass(_ctx.itemExists(_ctx.state.originalNotice?.topCapItemNumber) ? 'text-success' : 'text-danger')
                            }, " (" + _toDisplayString(_ctx.state.originalNotice?.topCapItemNumber) + ") ", 3)
                          ]),
                          _createVNode(_component_dropdown_autocomplete_single_select, {
                            "model-value": _ctx.state.notice?.topCapItemNumber,
                            loading: _ctx.state.loading,
                            data: _ctx.state.items,
                            "search-by": "customerItemNumber",
                            "empty-text": _ctx.getTranslation('core.common.noItemsFound'),
                            "display-name": "name",
                            disabled: _ctx.itemExists(_ctx.state.originalNotice?.topCapItemNumber),
                            onOnSelect: _ctx.selectTopCapItemNumber
                          }, null, 8, ["model-value", "loading", "data", "empty-text", "disabled", "onOnSelect"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.state.notice?.palletized)
                    ? (_openBlock(), _createBlock(_component_b_col, {
                        key: 1,
                        cols: "4"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("label", null, [
                            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.domain.palletContainerNumber')) + " ", 1),
                            _createElementVNode("span", {
                              class: _normalizeClass(_ctx.itemExists(_ctx.state.originalNotice?.palletContainerItemNumber) ? 'text-success' : 'text-danger')
                            }, " (" + _toDisplayString(_ctx.state.originalNotice?.palletContainerItemNumber) + ") ", 3)
                          ]),
                          _createVNode(_component_dropdown_autocomplete_single_select, {
                            "model-value": _ctx.state.notice?.palletContainerItemNumber,
                            loading: _ctx.state.loading,
                            data: _ctx.state.items,
                            "search-by": "customerItemNumber",
                            "empty-text": _ctx.getTranslation('core.common.noItemsFound'),
                            "display-name": "name",
                            disabled: _ctx.itemExists(_ctx.state.originalNotice?.palletContainerItemNumber),
                            onOnSelect: _ctx.selectPalletContainerItemNumber
                          }, null, 8, ["model-value", "loading", "data", "empty-text", "disabled", "onOnSelect"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "go-back"]))
}